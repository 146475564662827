ion-text.form-label {
  color: var(--ion-color-dark-tint);
  font-size: 1rem !important;
  width: 100% !important;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  &.required {
    &:after {
      margin-left: 2px;
      font-weight: bold;
      font-size: large;
      color: var(--ion-color-danger);
      content: "*";
      line-height: 1rem;
    }
  }
}

ion-input {
  --height: var(--btn-height) !important;
  --background: rgba(0, 0, 0, 0.05) !important;
  --padding-start: 24px !important;
  --color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
}

ion-searchbar {
  --background: rgba(0, 0, 0, 0.05) !important;
  --color: var(--ion-color-primary) !important;
  --border-radius: var(--border-radius-size) !important;
  --icon-color: var(--ion-color-medium) !important;
  --clear-button-color: var(--ion-color-medium) !important;
}

ion-textarea {
  --background: rgba(0, 0, 0, 0.05) !important;
  --padding-start: 24px !important;
  --color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  margin-top: 8px;
}

ion-select {
  height: var(--btn-height) !important;
  background: rgba(0, 0, 0, 0.05) !important;
  --padding-start: 24px !important;
  color: var(--ion-color-primary) !important;
  border-radius: var(--border-radius-size);
  padding: 0 16px;
  margin-top: 8px;
}

ion-text {
  font-size: 1rem !important;
}
